body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.RaLayout-appFrame {
    margin-top: 80px !important;
}

/*header > div.MuiToolbar-root {*/
/*    min-height: 76px !important;*/
/*}

/*#main-content {*/
/*    padding-top: 10px;*/
/*}*/

#main-content .MuiFormControl-root, #main-content .RaSimpleFormIterator-form {
    width: 100%;
}

#main-content .RaSimpleFormIterator-form > .MuiFormControl-root {
    width: 100%;
}

#main-content .MuiAutocomplete-root > .MuiFormControl-root {
    width: 100%;
}

@media (min-width: 600px) {
    #main-content .MuiFormControl-root, #main-content .MuiAutocomplete-root {
        width: 75%;
    }
}

@media (min-width: 900px) {
    #main-content .MuiFormControl-root, #main-content .MuiAutocomplete-root {
        width: 50%;
    }
}

div.css-1l0huk-RaLoadingIndicator-root {
    display: none;
}

.newReviewCorner {
    z-index: 99;
    width: 0;
    height: 0;
    border-top: 75px solid #dd262be6;
    border-right: 75px solid transparent;
}

.newReviewCorner span {
    position:absolute;
    top: -62px;
    left: 6px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    transform: rotate(-45deg);
    display:block;
    color: #fff;
}